<div *ngIf="loaded; else loading">
  <div>
    <h3>{{exercise?.name}}</h3>
  </div>
  <p-treeTable [value]="treeNodes" [columns]="cols" [scrollable]="true" >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr [ngClass]="{ 'main-row': rowData.isMainRow === true, 'child-row': rowData.isMainRow !== true }" [ttRow]="rowNode">
        <td *ngFor="let col of columns; let i = index">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
  <div class="mt-1">
    <p-calendar [(ngModel)]="date" view="month" dateFormat="mm/yy" [readonlyInput]="true" (onClose)="onCalendarClose()"></p-calendar>
  </div>
</div>
<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
