<div class="mt-1">
  <form (ngSubmit)="onSubmit(addExerciseForm)" #addExerciseForm="ngForm">
    <div class="card flex flex-column md:flex-row gap-3">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-circle"></i>
      </span>
        <input name="inputUsername" [(ngModel)]="exerciseName" pInputText placeholder="Exercise name"/>
      </div>
    </div>
    <div class="mt-1">
      <p-button type="submit" [disabled]="exerciseName.length <= 3" label="Add"></p-button>
    </div>
  </form>
</div>
