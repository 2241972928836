<div *ngIf="plansLoaded else loading">
  <div class="mt-1">
    <div>
      <p-tree class="w-full md:w-30rem" [value]="plansTree">
        <ng-template let-node pTemplate="reportNode">
          <b (click)="onReportPressed(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
        </ng-template>

        <ng-template let-node pTemplate="editNode">
          <b (click)="onEditPressed(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
        </ng-template>

        <ng-template let-node pTemplate="deleteNode">
          <b (click)="onDeletePressed(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
        </ng-template>

        <ng-template let-node pTemplate="default">
          <b class="text-700 hover:text-primary">{{ node.label }}</b>
        </ng-template>

        <ng-template let-node pTemplate="renameNode">
          <b (click)="rename(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
        </ng-template>
      </p-tree>
    </div>
  </div>
  <div class="mt-1">
    <div>
      <p-accordion>
        <p-accordionTab [disabled]="totalPlans>= PLANS_PER_ACCOUNT" [header]="'Add new plan ' + totalPlans + '/' + PLANS_PER_ACCOUNT">
          <app-add-plan></app-add-plan>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
  <div class="mt-1">
    <div>
      <p-accordion>
        <p-accordionTab header="Schedule">
          <app-schedule-plans
          [plans]="plans"
          >
          </app-schedule-plans>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
<div>
  <p-dialog header="New name" [(visible)]="renameModalVisible">
    <div class="flex py-2 justify-content-center">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-pencil"></i>
      </span>
        <input name="renameField" [(ngModel)]="renameModalPlan.name" pInputText placeholder="New name"/>
      </div>

    </div>
    <div class="mt-1">
      <p-button (click)="updateExercise()" label="Rename"></p-button>
    </div>
  </p-dialog>
</div>
<div>
  <p-confirmDialog [style]="{width: '70vw'}"></p-confirmDialog>
</div>
<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
