<div class="mt-1">
<form (ngSubmit)="onSubmit()">
  <div class="card flex flex-column md:flex-row gap-3">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <input name="inputUsername" [(ngModel)]="inputUsername" pInputText placeholder="Username"/>
    </div>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-key"></i>
      </span>
      <input type="password" name="inputPassword" [(ngModel)]="inputPassword" pInputText placeholder="Password"/>
    </div>
  </div>
  <div class="mt-1">
    <p-button type="submit" label="Submit"></p-button>
  </div>
</form>
</div>
