<form (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="inputConfirmationCode">Confirmation Code</label>
    <input type="text"
           class="form-control"
           id="inputConfirmationCode"
           name="inputConfirmationCode"
           placeholder="insert code..."
           [(ngModel)]="inputConfirmationCode">
  </div>
  <button type="submit" class="btn btn-primary">Verify</button>
</form>
