<div *ngIf="loaded; else loading">
  <div>
    <h3>{{exercise?.name}}</h3>
  </div>
  <div style="overflow-x: auto;">
  <div class="card mt-3" [style.width.px]='chartWidth' [ngStyle]="{'min-width': '100%'}">
    <p-chart type="bar" [data]="seriesData" [options]="generalBarOptions"></p-chart>
  </div>
    </div>
  <div style="overflow-x: auto;">
  <div class="card mt-3" [style.width.px]='chartWidth' [ngStyle]="{'min-width': '100%'}">
    <p-chart type="line" [data]="volumeData" [options]="generalPlotOptions"></p-chart>
  </div>
    </div>
  <div style="overflow-x: auto;">
  <div class="card mt-3" [style.width.px]='chartWidth' [ngStyle]="{'min-width': '100%'}">
    <p-chart type="line" [data]="repetitionData" [options]="generalPlotOptions"></p-chart>
  </div>
    </div>
  <div style="overflow-x: auto;">
  <div class="card mt-3" [style.width.px]='chartWidth' [ngStyle]="{'min-width': '100%'}">
    <p-chart type="line" [data]="maxWeightData" [options]="generalPlotOptions"></p-chart>
  </div>
    </div>
</div>

<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
