import { Component } from '@angular/core';

@Component({
  selector: 'app-gm-icon',
  templateUrl: './gm-icon.component.html',
  styleUrls: ['./gm-icon.component.css']
})
export class GmIconComponent {

}
