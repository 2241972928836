import {Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-general-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {
  }

}
