<div *ngIf="report; else loading" class="mt-1">
  <div class="ml-5 mr-5 mt-3">
    <p-button styleClass="w-12" [disabled]="!unsavedChanges" label="Save" (click)="saveReport()"></p-button>
  </div>
  <div class="mt-2 w-full">
    <p-table
      #dt
      [value]="sets"
      [rows]="10"
      [rowHover]="true"
      dataKey="id"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pResizableColumn="name">Repetitions</th>
          <th pResizableColumn="price">Weight</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-set>
        <tr>
          <td class="col-3" [pEditableColumn]="set.repetitions" pEditableColumnField="repetitions">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber inputStyleClass="w-3" inputId="integeronly" [(ngModel)]="set.repetitions" (ngModelChange)="onTableNumberChange()"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ set.repetitions }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="col-4" [pEditableColumn]="set.weight.mass" pEditableColumnField="mass">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber inputStyleClass="w-5" inputId="integeronly" [(ngModel)]="set.weight.mass" (ngModelChange)="onTableNumberChange()"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ set.weight.mass }}kg
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="col-2">
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                    (click)="deleteSeries(set)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex flex-column align-items-center justify-content-center">
      <div class="mt-5">
        <button pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-success"
                (click)="addSeries()"></button>
      </div>
    </div>
  </div>
</div>
<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
