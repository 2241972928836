<div *ngIf="loaded; else loading">
  <div class="mt-1">
    <p-tree class="w-full md:w-30rem" [value]="exercisesTree" [filter]="true" filterPlaceholder="Find Exercise">
      <ng-template let-node pTemplate="default">
        <b class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>

      <ng-template let-node pTemplate="statisticsNode">
        <b (click)="navigateToStatistics(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>
      <ng-template let-node pTemplate="editNode">
        <b (click)="editExercise(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>
      <ng-template let-node pTemplate="renameNode">
        <b (click)="rename(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>
      <ng-template let-node pTemplate="deleteNode">
        <b (click)="onDeletePressed(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>
      <ng-template let-node pTemplate="statisticsCsvNode">
        <b (click)="downloadCsv(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>
      <ng-template let-node pTemplate="chartsNode">
        <b (click)="navigateToCharts(node.data)" class="text-700 hover:text-primary">{{ node.label }}</b>
      </ng-template>
    </p-tree>
  </div>
  <div class="mt-1">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="0" [rows]="maxItemPerPage" [totalRecords]="totalExercises" [rowsPerPageOptions]="[10, 25, 50]"></p-paginator>
  </div>
  <div class="mt-1">
    <p-accordion>
      <p-accordionTab [disabled]="totalExercises>= EXERCISES_PER_ACCOUNT" [header]="'Add new exercise ' + totalExercises + '/' + EXERCISES_PER_ACCOUNT">
        <app-add-exercise></app-add-exercise>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
<div>
  <p-dialog header="New name" [(visible)]="renameModalVisible">
    <div class="flex py-2 justify-content-center">
      <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-pencil"></i>
      </span>
        <input name="renameField" [(ngModel)]="renameModalExercise.name" pInputText placeholder="New name"/>
      </div>

    </div>
    <div class="mt-1">
      <p-button (click)="updateExercise()" label="Rename"></p-button>
    </div>
  </p-dialog>
</div>
<div>
  <p-confirmDialog [style]="{width: '70vw'}"></p-confirmDialog>
</div>
<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
