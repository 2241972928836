<div *ngIf="loaded; else loading">
  <div class="flex justify-content-center mt-1">
    <h3>{{exercise?.name}}</h3>
  </div>
  <div class="container">
    <div class="flex justify-content-center">
      <p-calendar (onSelect)="dateSelected()" (onClose)="calendarClosed()" (onMonthChange)="monthChanged($event)" [(ngModel)]="date">
        <ng-template pTemplate="date" let-date>
          <div [ngClass]="{'red-day': isSpecialDate(date, forceUpdate, report)}">
            {{date.day}}
          </div>
        </ng-template>
      </p-calendar>
    </div>
    <div *ngIf="exercise" class="">
      <app-edit-report-table (reportChange)="reportChanged($event)" [report]="report" [exerciseId]="exercise.id"></app-edit-report-table>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="mt-2">
    <app-spinner></app-spinner>
  </div>
</ng-template>
