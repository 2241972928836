<div *ngIf="loaded; else loading">
  <div>
    <h3>{{plan.name}}</h3>
  </div>
  <div class="ml-5 mr-5 mt-3">
    <p-button styleClass="w-12" [disabled]="!isPlanTouched" label="Save" (click)="onSavePressed()"></p-button>
  </div>
  <div class="mt-2 mr-5-sm">
    <p-pickList [source]="sourceExercise" [target]="targetExercise" sourceHeader="Available" targetHeader="Selected"
                [dragdrop]="isDragdropEnabled()" [responsive]="true" [sourceStyle]="{ height: '30rem' }"
                [targetStyle]="{ height: '30rem' }" filterBy="name" sourceFilterPlaceholder="Search by name"
                targetFilterPlaceholder="Search by name" breakpoint="1400px"
                [showTargetControls]="false" [showSourceControls]="false"
                (onMoveToSource)="onItemsMoved()"
                (onMoveToTarget)="onItemsMoved()"
    >
      <ng-template let-exercise pTemplate="item">
        <div class="flex flex-wrap p-2 align-items-center gap-3">
          <div class="flex-1 flex flex-column gap-2">
            <span class="font-bold">{{ exercise.name }}</span>
          </div>
        </div>
      </ng-template>
    </p-pickList>
  </div>
</div>
<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
