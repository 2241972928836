<div *ngIf="loaded; else loading" class="mt-1">
  <div>
    <h3>{{plan?.name}}</h3>
  </div>
  <p-accordion>
    <p-accordionTab *ngFor="let singleExercise of exercises" [header]="singleExercise.name">
      <app-lift-exercise-menu [exercise]=singleExercise></app-lift-exercise-menu>
    </p-accordionTab>
  </p-accordion>
</div>
<div>
  <ng-template #loading>
    <div class="mt-2">
      <app-spinner></app-spinner>
    </div>
  </ng-template>
</div>
